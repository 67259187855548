export const evaluateMinMaxChanges = (min, max, type) => {
  const handleTwoInputsValues = (minimum, maximum) => {
    minimum = Number(minimum);
    maximum = Number(maximum);
    let defaultValues = {min: '', max: ''};

    if (!minimum && !maximum) {
      return defaultValues;
    }

    if (!minimum || !maximum) {
      if (!minimum && maximum) {
        defaultValues.max = maximum.toString();
      } else {
        defaultValues.min = minimum.toString();
      }
      return defaultValues;
    }

    defaultValues = minimum > maximum ?
      { max: minimum.toString(), min: maximum.toString() } :
      { max: maximum.toString(), min: minimum.toString() };
    return defaultValues;
  };

  const actualMin = min;
  const actualMax = max;
  return handleTwoInputsValues(actualMin, actualMax, type);
};

export const fixMinMaxOrder = (value) => {
  const entries = Object.entries(value);
  if ('min' in value || 'max' in value) {
    // eslint-disable-next-line no-unused-vars
    const sorted = entries.sort((a, _) => {
      return a[0] === 'min' ? -1 : 1;
    });
    return sorted;
  }
  return entries;
};
