import React, {useContext, useEffect, useMemo, useState} from 'react';
import {PortalConfigContext} from '../../../../../config/portal';

import {
  BREAKPOINTS,
  getBreakpoint,
  isSafelyInitializedWithLayout,
  isTabletOrSmallLaptopResolution
} from '../../../../../utils/commonHelper';
import {ThreeColumnListing} from '../ThreeColumnListing';
import PropTypes from 'prop-types';
import {Cookies} from 'react-cookie';
import {useTPPServices} from '../../../../../tppServices/tppDIHooks';
import {getMessages} from '../../../../../tppServices/translations/messages';
import ListingsWithSponsored from './ListingsWithSponsored';
import {
  buildBasicThreeColumnListingProps,
  buildEnhancedListingProps,
  buildOemListingProps,
  buildSponsoredListingProps, listingInNewTab,
  listingIsEnhanced,
  listingIsOemModel,
  MAX_DESKTOP_ADS
} from '../../../../../utils/propsBuilder/listingPropsBuilder';
import {EXPRESS_BRIDGE, getExpressBridgeService} from '../../../../../tppServices/expressBridgeService';
import AdSlot from '../../../../../components/Ads/AdSlot';

export const ThreeColumnListings = ({
    fuzzySponsoredBoats = [],
    listings,
    teaserRate,
    formatMessage: t,
    locale,
    tracking,
    setListingClick,
    userCurrency = '',
    isBranded,
    isLoading,
    newPageAdsConfig
  }) => {
  let desktopAdCount = 0;
  let tabletSmallLaptopAdCount = 0;
  let position = 0;
  let standardPosition = 0;
  const [device, setDevice] = useState('');
  const context = useContext(PortalConfigContext);
  const [shouldOpenInNewTab, setShouldOpenInNewTab] = useState(listingInNewTab(context, device));
  const {boatsConstants, tpp} = useTPPServices();
  const messages = getMessages();
  const expressBridge = getExpressBridgeService(tpp);
  expressBridge.shareWithExpress(EXPRESS_BRIDGE.LISTING_PARSABLE, true);
  const {
    LISTING_SPONSORED, LISTING_ENHANCED, LISTING_STANDARD, LISTING_MANUFACTURER,
  } = boatsConstants;
  const enableExtraSponsoredListings = !!context.supports?.enableExtraSponsoredListings;
  const isSearchMobileBoxAds = !!context.supports?.isSearchMobileBoxAds;
  const currency = userCurrency;
  // fuzzySponsoredBoats were displayed in the middle of listings with "sponsored" tag
  const renderWithSponsored = enableExtraSponsoredListings && fuzzySponsoredBoats?.length > 0;
  const adsIndexOffset = enableExtraSponsoredListings ? 3 : 0;
  const isMobile = device === BREAKPOINTS.mobile;

  const updateDevice = (breakpoint) => {
    setDevice(breakpoint);
    setShouldOpenInNewTab(listingInNewTab(context, breakpoint));
  };

  // Set initial breakpoint for correct device layout
  useEffect(() => {
    const breakpoint = isTabletOrSmallLaptopResolution()
      ? BREAKPOINTS.tabletOrSmallLaptop
      : getBreakpoint();
    updateDevice(breakpoint);
  }, []);

  // Attach event listener to detect resize event
  useEffect(() => {
    const handleResize = () => {
      const breakpoint = isTabletOrSmallLaptopResolution()
        ? BREAKPOINTS.tabletOrSmallLaptop
        : getBreakpoint();
      updateDevice(breakpoint);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //TODO: We can remove this useEffect when we get rid off extra ThreeColumnListings renders
  useEffect(() => {
    tracking.registerProductImpressions(true);
  });


  const supportsMonthlyPayment = useMemo(() => !!context.languages[locale]?.supports?.monthlyPayment, []);
  const msgsMonthlyPayment = useMemo(() => messages.boatLoansContent.monthlyPaymentTooltip, []);
  const isDesktop = useMemo(() => isSafelyInitializedWithLayout('desktop'), []);
  const translations = {t, messages, msgsMonthlyPayment};

  const preparePropsDependencies = (position, currency) => {
    const priceProps = {currency, teaserRate, supportsMonthlyPayment};
    translations.priceSuffix = !isDesktop ? '*' : '';
    const propFlags = {isBranded, shouldOpenInNewTab};
    const makeDependencies = {device, tracking, locale, translations, position};
    const actions = {trackClick};
    return {priceProps, propFlags, makeDependencies, actions};
  };

  const prepareListingProps = (listing, position, currency) => {
    const {priceProps, propFlags, makeDependencies, actions} = preparePropsDependencies(position, currency);
    if (listingIsEnhanced(listing, LISTING_ENHANCED)) {
      makeDependencies.defaultType = LISTING_ENHANCED;
      const enhancedProps = buildEnhancedListingProps(listing, context, actions, makeDependencies, propFlags, priceProps);
      enhancedProps.targetBlank = shouldOpenInNewTab;
      return enhancedProps;
    } else if (listingIsOemModel(listing, LISTING_MANUFACTURER)) {
      makeDependencies.defaultType = LISTING_MANUFACTURER;
      const oemProps = buildOemListingProps(listing, context, actions, makeDependencies, propFlags, priceProps);
      oemProps.targetBlank = shouldOpenInNewTab;
      return oemProps;
    }
    const standardListing = buildBasicThreeColumnListingProps(listing, context, makeDependencies, propFlags, priceProps, actions);
    standardListing.type = LISTING_STANDARD;
    standardListing.targetBlank = shouldOpenInNewTab;
    return standardListing;
  };

  const appendThreeColListing = (listingArr, listing, position, index, currency, counter, start) => {
    const itemKey = `${counter}-${index}`;
    const LAST_AD_POSITION = 28 + adsIndexOffset;
    const adIndex = 4 + adsIndexOffset;
    const breakPoint = 5;
    const startIndex = index - adIndex >= 0;

    if (
      startIndex &&
      (desktopAdCount < MAX_DESKTOP_ADS &&
      (index === adIndex || (index - adIndex) % breakPoint === 0))
      || (position === LAST_AD_POSITION && desktopAdCount < MAX_DESKTOP_ADS + 1)
    ) {
      let count = desktopAdCount += 1;
      if ((!isBranded || count < 3)) {
        listingArr.push(
          <span key={`desktop-ad-${itemKey}`} className="column-ads">
            <AdSlot adParams={newPageAdsConfig} adSlot={`inline-box-${count}`} />
          </span>
        );
      }
      position += 1;
    }
    const googlePosition = start;
    const listingProps = prepareListingProps(listing, position, currency);

    listingArr.push( <ThreeColumnListing key={itemKey} {...listingProps} position={ googlePosition } id={listing.id} /> );
    return listingArr;
  };

  const appendListingOnTabletOrSmallLaptop = (listingArr, listing, position, standardPosition, index, currency) => {
    const positionIndex1 = 4 + adsIndexOffset;
    const positionIndex2 = 13 + adsIndexOffset;
    const positionIndex3 = 22 + adsIndexOffset;
    if (standardPosition === positionIndex1 || standardPosition === positionIndex2 || standardPosition === positionIndex3) {
      let adSlot1 = tabletSmallLaptopAdCount += 1;
      let adSlot2 = tabletSmallLaptopAdCount += 1;
      if ((!isBranded || standardPosition === positionIndex1)) {
        listingArr.push(
          <span key={`add-tablet-${standardPosition}`} className="column-ads tablet-ads">
            <AdSlot adParams={newPageAdsConfig} adSlot={`inline-box-${adSlot1}`} />
            {
              standardPosition !== positionIndex3 &&
              <AdSlot adParams={newPageAdsConfig} adSlot={`inline-box-${adSlot2}`} />
            }
          </span>
        );
      }
      position += 1;
    }
    const googlePosition = standardPosition;
    const props = prepareListingProps(listing, position, currency);
    listingArr.push( <ThreeColumnListing key={`col3-tablet-${index}`} {...props} position={ googlePosition } id={listing.id} /> );
    return listingArr;
  };

  const appendMobileAdToListingList = (listingArr, listing, index) => {
    let mobileBoxParams = null;
    const mobileIndex1 = 3 + adsIndexOffset;
    const mobileIndex2 = 9 + adsIndexOffset;
    const mobileIndex3 = 14 + adsIndexOffset;
    const mobileIndex4 = 20 + adsIndexOffset;
    const mobileIndex5 = 27 + adsIndexOffset;
    if (index === mobileIndex1) {
      mobileBoxParams = 'mobile-box-1';
    }

    if (index === mobileIndex2) {
      mobileBoxParams = 'mobile-box-2';
    }

    if (index === mobileIndex3 && !isBranded) {
      mobileBoxParams = 'mobile-box-3';
    }

    if (index === mobileIndex4 && !isBranded) {
      mobileBoxParams = 'mobile-box-4';
    }

    if ( index === mobileIndex5 && !isBranded) {
      mobileBoxParams = 'mobile-box-5';
    }

    if (mobileBoxParams && isSearchMobileBoxAds) {
      listingArr.push(
        <div className="mobile-box" key={`srp-mobile-${index}`}>
          <AdSlot adParams={newPageAdsConfig} adSlot={mobileBoxParams} />
        </div>
      );
    }
    return listingArr;
  };

  const prepareFuzzySponsoredProps = (fuzzySponsoredBoat, position) => {
    position += 1;
    const { priceProps, propFlags, makeDependencies, actions } = preparePropsDependencies(position, currency);
    propFlags.isExactMatchSponsored = false;
    makeDependencies.defaultType = LISTING_SPONSORED;
    makeDependencies.position = position;
    propFlags.debug = true;
    const fuzzyListingProps = buildSponsoredListingProps(fuzzySponsoredBoat, context, actions, makeDependencies, propFlags, priceProps);
    fuzzyListingProps.targetBlank = shouldOpenInNewTab;
    return fuzzyListingProps;
  };

  const createListingWithChunk = (chunk, counter, start = 0) => {
    try {
      return chunk.reduce((listingArr, listing, index) => {
        position += 1;
        standardPosition += 1;
        const shifted = start + index + 1;
        if (device === BREAKPOINTS.tabletOrSmallLaptop) {
          return appendListingOnTabletOrSmallLaptop(listingArr, listing, position, standardPosition, index, currency);
        }
        if (device !== BREAKPOINTS.desktop) {
          appendMobileAdToListingList(listingArr, listing, position - 1);
        }
        return appendThreeColListing(listingArr, listing, position, index, currency, counter, shifted);
      }, []);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in createListingWithChunk', e);
      return [];
    }
  };

  const createListingsWithSponsoredChunk = (fuzzySponsoredBoatList, position, standardPosition, setListingClick, tracking, start) => {
    try {
      return fuzzySponsoredBoatList.map((fuzzySponsoredBoat, i) => {
        position += 1;
        const googlePosition = start + i + 1;
        const inlineProps = prepareFuzzySponsoredProps(fuzzySponsoredBoat, position);
        return (
          <ThreeColumnListing
            key={ fuzzySponsoredBoat.id }
            {...inlineProps}
            position={ googlePosition }
            id={fuzzySponsoredBoat.id}
          />
        );
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error in createListingsWithSponsoredChunk', e);
      return [];
    }
  };

  const trackClick = (listingId, listingType, region, make) => {
    return () => {
      setListingClick(listingId, listingType, region, make);
    };
  };

  const prepareListingsWithChunks = () => {
    const sliceBreakPoint = device === BREAKPOINTS.tabletOrSmallLaptop ? 9 + adsIndexOffset : 10 + adsIndexOffset;
    const firstSlice = listings.slice(0, sliceBreakPoint);
    const secondSlice = listings.slice(sliceBreakPoint);
    const start2Position = sliceBreakPoint + fuzzySponsoredBoats.length;
    const topListingBlock = createListingWithChunk(firstSlice, 1);
    const bottomListingBlock = createListingWithChunk(secondSlice, 2, start2Position);
    const startPosition = sliceBreakPoint;
    const sponsoredListingBlock = createListingsWithSponsoredChunk(fuzzySponsoredBoats, position, standardPosition, setListingClick, tracking, startPosition);
    return {topListingBlock, bottomListingBlock, sponsoredListingBlock};
  };

  const getListingWithSponsoredProps = () => {
    const {topListingBlock, bottomListingBlock, sponsoredListingBlock} = prepareListingsWithChunks();
    return {
      isSponsoredEnabled: renderWithSponsored,
      device,
      // first group of listings
      topListingBlock,
      // additional sponsored listings
      sponsoredListingBlock,
      // second group of listings
      bottomListingBlock};
  };


  if (isMobile && isLoading) {
    return null;
  }

  return <ListingsWithSponsored {...getListingWithSponsoredProps()} />;
};

ThreeColumnListings.propTypes = {
  fuzzySponsoredBoats: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  listings: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  teaserRate: PropTypes.number,
  formatMessage: PropTypes.func,
  locale: PropTypes.string,
  adParams: PropTypes.object,
  setListingClick: PropTypes.func,
  tracking: PropTypes.shape({
    region: PropTypes.string,
    page: PropTypes.string,
    pageSize: PropTypes.string,
    setProductImpression: PropTypes.func,
    registerProductImpressions: PropTypes.func
  }),
  userCurrency: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies),
  isBranded: PropTypes.bool,
  isLoading: PropTypes.bool,
  newPageAdsConfig: PropTypes.object
};
