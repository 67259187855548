import React, { memo } from 'react';
import {MinMaxInput} from '../FilterSections/MinMaxInput';
import { useIntl } from 'react-intl';
import {
  SRP_LITERALS,
  useMinMaxLabels, useMinMaxTypers, defaultGetMinMax, useDelayedTyping
} from '../../RefineSearch/FilterHelpers/useSRPFilters';
import PropTypes from 'prop-types';
import { getMessages } from '../../../../../tppServices/translations/messages';
import { useValidateNumberRange } from '../FilterSections/hooks/numberRangeForm';

const {MIN, MAX} = SRP_LITERALS;

const FilterYear = ({handleDataChange, isThreeColumnLayout, min = '', max = '' }) => {
  const messages = getMessages();
  const intl = useIntl();
    const t = intl.formatMessage;
  const validateYear = ({ min, max }) => {
    const errors = {};
    if ((max !== '') && (+min > +max)) {
      errors.max = t(messages.yearMaxError);
    }

    return errors;
  };
  const filterNameKey = 'year';
  const { validateNumberRange, errorMessages, invalidFields } = useValidateNumberRange(filterNameKey, validateYear);
  const {placeholderMin, placeholderMax, titleMin, titleMax, messageTo} = useMinMaxLabels(isThreeColumnLayout, messages, filterNameKey);
  const {onType, onTypeEnd, minRef, maxRef} = useMinMaxTypers(handleDataChange, filterNameKey, defaultGetMinMax, min, max);
  const onValidateType = (type, value, event) => {
    const min = defaultGetMinMax(minRef);
    const max = defaultGetMinMax(maxRef);
    validateNumberRange({ min, max });
    onType(type, value, event);
  };
  const {onStopTyping} = useDelayedTyping(onValidateType, onTypeEnd);

  return (
    <div className="search-filter year">
      <div className="search-filter-group">
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MIN}
          placeholder={placeholderMin}
          value={min}
          title={titleMin}
          cssClass={'year-min'}
          onChange={onStopTyping}
          ref={minRef}
          invalid={ invalidFields.includes(MIN) }
        />
        <span>{messageTo}</span>
        <MinMaxInput
          filterName={filterNameKey}
          prefix={MAX}
          placeholder={placeholderMax}
          value={max}
          title={titleMax}
          cssClass={'year-max'}
          onChange={onStopTyping}
          ref={maxRef}
          invalid={ invalidFields.includes(MAX) }
        />
      </div>
      { errorMessages.length > 0 && (
        <div className="number-range-error-messages" data-error="true">
          { errorMessages.map((error, index) => <div key={index}>{ error }</div>) }
        </div>
      )}
    </div>
  );
};

FilterYear.propTypes = {
  isThreeColumnLayout: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  position: PropTypes.string,
};

export default memo(FilterYear);
