import React, { memo } from 'react';
import {useIntl} from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  SRP_LITERALS, useCurrency, useMinMaxLabels, useMinMaxTypers, defaultGetMinMax, useDelayedTyping
} from '../../RefineSearch/FilterHelpers/useSRPFilters';
import {yieldToMain} from '../../../../../utils';
import { MinMaxInput } from '../FilterSections/MinMaxInput';
import { useValidateNumberRange } from '../FilterSections/hooks/numberRangeForm';
import PropTypes from 'prop-types';
import {SimpleDropdownList} from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import './styles.css';
import { getMessages } from '../../../../../tppServices/translations/messages';

const {MIN, MAX, CURRENCIES} = SRP_LITERALS;

const CurrencyDropDown = ({show, handleCurrencyChange, currencyKey, selectedCurrency}) => {
  const dispatch = useDispatch();
  if (!show) {
    return null;
  }
  const onChangeCurrency = async(value) => {
    dispatch({type: 'GET_DATA_LOADING'});
    await yieldToMain();
    handleCurrencyChange('currency', value);
  };
  return (<div className="price-dropdown">
    <SimpleDropdownList
      key={currencyKey}
      selected={selectedCurrency}
      onChange={(e, value) =>
        onChangeCurrency(value)
      }
      items={CURRENCIES}
    />
  </div>);
};

CurrencyDropDown.propTypes = {
  handleCurrencyChange: PropTypes.func,
  currencyKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectedCurrency: PropTypes.string,
  show: PropTypes.bool
};

const CurrencySymbol = ({display, currencySymbol}) => {
  if (!display) {
    return null;
  }
  return <span className="symbol">{currencySymbol}</span>;
};

CurrencySymbol.propTypes = {
  currencySymbol: PropTypes.string,
  display: PropTypes.bool
};

const FilterPrice = ({handleDataChange, isThreeColumnLayout, userCurrency, min = '', max = ''}) => {
  const intl = useIntl();
  const t = intl.formatMessage;
  const validatePrice = ({ min, max }) => {
    const errors = {};
    if ((max !== '') && (+min > +max)) {
      errors.max = t(messages.priceMaxError);
    }

    return errors;
  };

  const messages = getMessages();

  const filterNameKey = 'price';
  const { validateNumberRange, errorMessages, invalidFields } = useValidateNumberRange(filterNameKey, validatePrice);


  const {placeholderMin, placeholderMax, titleMin, titleMax, messageTo} = useMinMaxLabels(isThreeColumnLayout, messages, filterNameKey);
  const {
    isUsersCurrencyEnabled,
    smallMinClass,
    smallMaxClass,
    currencyKey,
    selectedCurrency,
    currencySymbol
  } = useCurrency(userCurrency, min, max);

  const {onType, onTypeEnd, minRef, maxRef} = useMinMaxTypers(handleDataChange, filterNameKey, defaultGetMinMax, min, max);

  const onValidateType = (type, value, event) => {
    const min = defaultGetMinMax(minRef);
    const max = defaultGetMinMax(maxRef);

    validateNumberRange({ min, max });
    onType(type, value, event);
  };

  const {onStopTyping} = useDelayedTyping(onValidateType, onTypeEnd);

  return (
    <div className="search-filter price">
      <div className="search-filter-group">
        <div className={smallMinClass}>
          <CurrencySymbol
            display={!!(smallMinClass && !isUsersCurrencyEnabled)}
            currencySymbol={currencySymbol} />
          <MinMaxInput
            filterName={filterNameKey}
            prefix={MIN}
            placeholder={placeholderMin}
            value={min}
            title={titleMin}
            cssClass={classnames('price-input', { 'price-min': !isUsersCurrencyEnabled })}
            onChange={onStopTyping}
            ref={minRef}
            invalid={ invalidFields.includes(MIN) }
            isPrice={true}
          />
        </div>
        <span>{messageTo}</span>
        <div className={smallMaxClass}>
          <CurrencySymbol
            display={!!(smallMaxClass && !isUsersCurrencyEnabled)}
            currencySymbol={currencySymbol} />
          <MinMaxInput
            filterName={filterNameKey}
            prefix={MAX}
            placeholder={placeholderMax}
            value={max}
            title={titleMax}
            cssClass={classnames('price-input', { 'price-max': !isUsersCurrencyEnabled })}
            onChange={onStopTyping}
            ref={maxRef}
            invalid={ invalidFields.includes(MAX) }
            isPrice={true}
          />
        </div>
        <CurrencyDropDown
          show={isUsersCurrencyEnabled}
          currencyKey={currencyKey}
          selectedCurrency={selectedCurrency}
          handleCurrencyChange={handleDataChange}/>
      </div>
      { errorMessages.length > 0 && (
        <div className="number-range-error-messages" data-error="true">
          { errorMessages.map((error, index) => <div key={index}>{ error }</div>) }
        </div>
      )}
    </div>
  );
};

FilterPrice.propTypes = {
  isThreeColumnLayout: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  cookies: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  handleDataChange: PropTypes.func,
  userCurrency: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  position: PropTypes.string,
};

export default memo(FilterPrice);
