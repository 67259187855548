import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Cookies } from 'react-cookie';
import get from 'lodash/get';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { injectIntl } from 'react-intl';
import { WordsmithContent, SeoContent as InterestingInformation } from '@dmm/react-common-components';
import { adsConfig, getTargeting } from './resources/adsConfig';
import adParams from './resources/adParams';
import { isMobileResolution } from '../../../utils/commonHelper';
import AdProvider from '../../../components/Ads/AdProvider';
import MobileRefineSearch from '../components/MobileRefineSearch';
import RefineSearchSideBar from '../components/RefineSearchSideBar';
import RelatedContent from '../components/RelatedContent';
import DynamicContent from '../components/DynamicContent';
import SeoContent from '../components/SeoContent';
import YoutubeVideos from '../components/YoutubeVideos';
import RelatedBoatArticles from '../components/RelatedBoatArticles';
import ExpertReviewsTitle from '../components/ExpertReviewsTitle';
import ResultsHeader from '../components/ResultsHeader';
import ResultsPagination from '../components/ResultsPagination';
import TypeChooser from '../components/TypeChooser';
import TypeChooserBranded from '../components/TypeChooser/Branded';
import AlertMessage from '../../../components/AlertMessage';
import BrandedHeader from '../../BrandedSearch/components/BrandedHeader';
import BrandedHeaderV2 from '../../BrandedSearch/components/BrandedHeaderV2';
import BreadCrumb from '../../../components/BreadCrumb';
import SponsoredListings from '../components/SponsoredListings';
import BoatList from '../../../components/BoatList';
import Meta from '../../../components/Meta';
import OEMDetails from '../components/OEMDetails';
import VehicleSchema from '../../../components/Schema/VehicleSchema';
import { getBreadCrumb } from '../utils/breadcrumb';
import { getBannerImage } from '../../../utils/partiesHelper';
import * as meta from '../utils/meta';
import { getTitle, getPrice, getLocation, getBrokerSpotlightInfo } from '../../../utils/listingHelper';
import { isSingleTypeOrClassFilter, isSingleMakeFilter } from '../../../utils/seoContentHelper';

import { setProductImpression, addLead, setEnhancedEcommerce, trackSearchCount } from '../../../store/actions/dataLayer';
import {getActiveParams, getDefaultParams, parseSearchParams} from '../../../utils/urlHelpers/boats';
import { getNumberOfPages } from '../../../utils/paginationHelper';
import { getBoatUrl } from '../../../utils/urlHelpers/boat';
import { listingPropTypes } from '../../../utils/commonPropTypes';
import { getConfig, PortalConfigContext } from '../../../config/portal';
import * as utils from '../../../store/utils';
import * as storage from '../../../utils/storage';
import { REDUCTION_REDIRECT_COOKIE, UNAVAILABILITY_REDIRECT_COOKIE } from '../../../constants/cookies';
import * as scroll from '../../../utils/scrollTo';

import { getAddress } from '../../../utils/commonHelper';
import {getCurrentLocale, getPortalName} from '../../../utils/language';
import { BrokerSpotlightCard } from '@dmm/lib-react-broker-card';
import '@dmm/lib-react-broker-card/dist/@dmm/lib-react-broker-card.min.css';
import ContactForm from '../../../components/ContactForm';
import { getCustomUom } from '../../../utils/uomHelper';
import TextTransform from '../utils/TextTransform';
import HeroImage from '../HeroImage';
import PaymentCalculator from '../../../components/PaymentCalculator';

import '@dmm/lib-react-videos/dist/@dmm/lib-react-videos.min.css';
import './styles.css';
import {getBoatConstantsFromI18n} from '../../../tppServices/translations/constants';
import { getMessages } from '../../../tppServices/translations/messages';
import { getBoatLoanConstants } from '../../../constants/BoatLoans';
import { getPageAdsData } from '../../../utils/ads/adsUtils';
import AdSlot from '../../../components/Ads/AdSlot';

class MainContent extends PureComponent {
  displayContactFormData = [];

  state = {
    alert: null,
    isMobileResolution: false,
    contactOpen: false,
    adRefreshKey: 0,
    isMounted: false,
    stickyLeaderboard: false
  }
  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
    this.boatLoansConstants = getBoatLoanConstants(this.context);
  }

  populateAlertMessage = () => {
    const { cookies, intl: { formatMessage: t } } = this.props;
    const messages = this.translationMessages;
    if (cookies.cookies[REDUCTION_REDIRECT_COOKIE]) {
      this.setState({
        alert: {
          mainText: t(messages.alertMessage.linkExpired.title),
          secondaryText: t(messages.alertMessage.linkExpired.description),
          url: get(this.props, 'location.pathname'),
        },
      });
    } else if (cookies.cookies[UNAVAILABILITY_REDIRECT_COOKIE]) {
      this.setState({
        alert: {
          mainText: t(messages.alertMessage.boatUnavailable.title),
          secondaryText: t(messages.alertMessage.boatUnavailable.description),
          url: get(this.props, 'location.pathname'),
        },
      });
    }

    cookies.remove(UNAVAILABILITY_REDIRECT_COOKIE, { path: '/' });
    cookies.remove(REDUCTION_REDIRECT_COOKIE, { path: '/' });
  }

  resizeHandler = () => {
    this.setState(
      {
        isMobileResolution: isMobileResolution()
      }
    );
  }

  saveSearchResults = () => {
    const nextPreviousData = {};
    const searchUrl = get(this.props, 'location.pathname', '');
    nextPreviousData.searchParams = parseSearchParams(searchUrl, this.props.mode === MainContent.MODES.branded, get(this.getCustomUom(), 'length', undefined));
    nextPreviousData.searchUrl = searchUrl;
    nextPreviousData.pageLow = parseInt(nextPreviousData.searchParams.page);
    const records = get(this.props, 'search.records', []);
    nextPreviousData.urls = records.map((listing) => getBoatUrl(listing));
    storage.setItem('nextPreviousData', JSON.stringify(nextPreviousData));
    if (get(getConfig(), 'supports.permutive', false)) {
      window?.permutiveHelper?.pageView && window.permutiveHelper.pageView();
    }
  }

  getPriceDisclaimer() {
    const { intl: { locale } } = this.props;
    return get(this.context, `languages.${locale}.currency.disclaimer`, false);
  }

  getCustomUom() {
    const { cookies, intl: { locale } } = this.props;
    const isDefaultLocaleUom = get(this.context, 'supports.defaultUoms', false);
    const supportedUoms = get(this.context, 'supportedUoms');
    const defaultLocaleUom = get(this.context, `languages.${locale}.defaultUom`);
    return getCustomUom(isDefaultLocaleUom, cookies, supportedUoms, defaultLocaleUom);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    this.populateAlertMessage();

    this.setState({ isMounted: true });
    const trackTotalSearchCountFlag = get(this.context, 'supports.trackTotalSearchCount', false);
    if (trackTotalSearchCountFlag) {
      this.props.trackSearchCount(this.props.search.count);
    }
  }

  componentDidUpdate(prevProps) {
    if (!utils.isServer()) {
      this.saveSearchResults();
    }
    if (this.state.alert &&
      get(this.props, 'location.pathname') !== get(prevProps, 'location.pathname')) {
      this.setState({ alert: null });
    }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('resize', this.scrollHandler);
    clearInterval(this.refreshAdInterval); // Clear the interval when the component is unmounted
  }

  // this function updates the adRefreshKey in the state
  refreshAd = () => {
    this.setState((prevState) => ({
      adRefreshKey: prevState.adRefreshKey + 1,
    }));
  };

  getHeaderContent(seoParams, portal) {
    return meta.getH1({params: seoParams, portal});
  }

  handleContactButtonClick = () => {
    const { spotlightDetails } = this.props;
    addLead(spotlightDetails.party.details.brokerSpotlightId, 'email lead', null);
    const boatConstants = getBoatConstantsFromI18n();
    setEnhancedEcommerce([ boatConstants.LISTING_PREMIUM_BROKER ], spotlightDetails.party.details.brokerSpotlightId);
    this.displayContactFormData = [];
    this.setState({ contactOpen: true });
  }

  handleContactButtonClose = () => {
    this.setState({ contactOpen: false });
    this.displayContactFormData = [];
  }

  renderHeader(seoParams, portal, similarBoatsForOEMs, supportsBrandedHeaderV2) {
    const { mode, partyDetails, spotlightDetails } = this.props;

    if (mode === MainContent.MODES.branded) {
      const brokerSpotlightFeature = get(this.context, 'pages.details.brokerSpotlight');
      const brokerSpotlightVersion = get(this.context, 'pages.details.brokerSpotlight.version', 1);
      let brokerSpotlightInfo;
      if (brokerSpotlightFeature && brokerSpotlightVersion === 2) {
        brokerSpotlightInfo = getBrokerSpotlightInfo(partyDetails, spotlightDetails.party, this.handleContactButtonClick, null, 'listing');
      }
      if ((brokerSpotlightVersion === 2) && brokerSpotlightInfo) {
        return <BrokerSpotlightCard brokerInfo={brokerSpotlightInfo} />;
      }
      else if (supportsBrandedHeaderV2) {
        return <BrandedHeaderV2
          bannerPath={getBannerImage(partyDetails.bannerPath)}
          description={partyDetails.description}
          multiLangDescriptions={partyDetails.multiLangDescriptions}
          logoPath={partyDetails.logoPath}
          displayName={partyDetails.displayName}
          seoParams={seoParams}
          portal={portal}
          address={partyDetails.address}
          mode={BrandedHeaderV2.SEARCH_TYPE.boats}
        />;
      }
      return <BrandedHeader
        bannerPath={partyDetails.bannerPath}
        description={partyDetails.description}
        multiLangDescriptions={partyDetails.multiLangDescriptions}
        logoPath={partyDetails.logoPath}
        logoUrl={partyDetails.logoUrl}
        displayName={partyDetails.displayName}
        seoParams={seoParams}
        portal={portal}
        address={partyDetails.address ? getAddress(partyDetails.address) : ''}
      />;
    }

    return <h1>{this.getHeaderContent(seoParams, portal, similarBoatsForOEMs)}</h1>;
  }

  getSponsoredBoats() {
    const sponsored = get(this.props, 'sponsored.records', []);
    return sponsored.map(listing => {
      listing.href = getBoatUrl(listing);
      return listing;
    });
  }

  getSeoContentH2Text = (interestingInformation) => {
    const h2 = interestingInformation.linkText;

    return new TextTransform(h2)
      .trim()
      .capitalizeFirstLetter()
      .removeExtraSpaces()
      .toString();
  };

  getDealerWithCityText = (dealerName, dealerCity) => {
    const { intl: { formatMessage: t } } = this.props;
    const messages = this.translationMessages;
    return t(messages.brandedSearch.SEO.breadcrumbs, { dealerName, dealerCity });
  };

  render() {
    const messages = this.translationMessages;
    const {CTA_APPLY_NOW_CALCULATOR_SRP} = this.boatLoansConstants;
    const params = get(this.props, 'match.params', {});
    const owner = get(this.props, 'match.params.owner', '');
    const makeModel = get(this.props, 'facets.makeModel', []);
    const facets = get(this.props, 'facets', {});
    const minMaxPercentilPrices = get(facets, 'minMaxPercentilPrices', []);
    const uom = get(this.context, 'uom.length.abbr');
    const customUom = this.getCustomUom();
    const defaultParams = getDefaultParams(params);
    const activeParams = getActiveParams(defaultParams);
    const pos = { page: defaultParams.page, pageSize: defaultParams.pageSize };
    const { isMobileResolution, isMounted } = this.state;
    const isTypeChooserEnabled = get(this.context, 'pages.searchResults.typeChooser.enabled');
    const portal = getPortalName(this.context);
    const contextAdParams = get(this.context, 'pages.searchResultsOneColumn.adsConfig', {});
    const pageAdsConfig = merge(contextAdParams, adsConfig);
    const url = get(this.props, 'location.pathname', '');
    const maxPages = get(this.context, 'pages.searchResults.pagination.maxPages', 357);
    const seoMakeInfo = get(this.props, 'seoMakeInfo');
    const seoParams = meta.getSEOParams(params, makeModel, seoMakeInfo);
    const { mode, partyDetails } = this.props;
    const toggleProductsFeatureAvailable = get(partyDetails, 'hasBothProductsEnginesAndBoats', false);
    const countListings = get(this.props, 'search.count', undefined);
    const listings = get(this.props, 'search.records');
    const numPages = getNumberOfPages(countListings, params) < maxPages ?
      getNumberOfPages(countListings, params) : maxPages;
    const currentPage = get(params, 'page', 1);
    let dealer;
    const isBranded = this.props.mode === MainContent.MODES.branded;
    const isSrpSeoEnabled = get(this.context, 'supports.enableSrpSeo', true);
    const extendedSeoContent = get(this.context, 'supports.extendedSeoContent', false);
    const tridentTeaserRate = get(this.props, 'tridentTeaserRate');
    const adsData = this.props?.adsData ?? {};
    const searchResultsContext = this.context?.pages?.searchResultsOneColumn;
    const adZones = searchResultsContext?.newAdsConfig?.adZones ?? {};
    const hasAdZones = Object.keys(adZones).length > 0;

    let newPageAdsConfig = {};
    if (hasAdZones) {
      const cdnUrl = this.context.client?.cdn?.endpoint;
      newPageAdsConfig = getPageAdsData(adZones, adsData, cdnUrl);
    }

    if (isBranded) {
      dealer = {
        id: defaultParams.owner.split('-').pop(),
        dealerLocation: get(listings, '0.owner.location.address')
      };
    }
    const targeting = getTargeting(
      defaultParams,
      (customUom && customUom.length) ? customUom.length.abbr : uom,
      dealer
    );
    const similarBoatsForOEMs = get(this.props, 'similarBoatsForOEMs');
    const metaTitle = meta.getMetaTitle({
      params: seoParams,
      maxPages: numPages,
      portal,
      isBranded,
      partyDetails
    });
    const metaDescription = meta.getMetaDescription({
      params: seoParams,
      maxPages: numPages,
      portal,
      isBranded,
      partyDetails,
      countListings,
      similarBoatsForOEMs
    });

    const supportsBrandedHeaderV2 = get(this.context, 'supports.brandedHeaderV2', false);
    const contentHeadClassName = (this.props.mode === MainContent.MODES.branded && supportsBrandedHeaderV2) ? 'content-head-v2' : 'content-head';
    const showThirdAdbox = get(this.context, 'supports.showThirdAdBox', false);
    if (!showThirdAdbox) {
      delete pageAdsConfig['div-gpt-ad-box-3'];
      delete pageAdsConfig['div-gpt-ad-leaderboard-bottom'];
      delete newPageAdsConfig?.['box-3'];
      delete newPageAdsConfig?.['leaderboard-bottom'];
    }
    const brokerSpotlightId = get(this.props, 'spotlightDetails.party.id', 0);
    const brokerName = get(this.props, 'spotlightDetails.party.details.brokerName', '');
    const normalizedName = get(this.props, 'spotlightDetails.party.details.normalizedBrokerName', '');
    const partyName = isBranded ?
      this.getDealerWithCityText(
        get(partyDetails, 'name', ''),
        get(partyDetails, 'address.city', ''))
      :
      get(this.props, 'partyDetails.name', '');
    const breadcrumbs = getBreadCrumb(defaultParams, seoParams, partyName, brokerName, seoMakeInfo);
    const priceDisclaimer = this.getPriceDisclaimer();
    const salesRep = {
      id: brokerSpotlightId,
      normalizedName
    };
    const interestingInformation = get(this.props, 'interestingInformation');
    const videos = get(this.props, 'videos', []);
    const relatedBoatArticles = get(this.props, 'relatedBoatArticles', []);
    const wordsmithContent = get(this.props, 'wordsmithContent');
    const { intl: { formatMessage: t } } = this.props;
    const trackingInfo = {region: this.props.intl?.locale?.toUpperCase(), portal: this.context?.name };
    const locale = getCurrentLocale();
    const supportsTridentCalculator = get(getConfig(), `languages.${locale}.supports.tridentCalculator`, false);

    return (
      <>
        <AdProvider
          url={url}
          isWorking={this.props.isWorking}
          targeting={targeting}
          adsConfig={pageAdsConfig}
        >
          <HeroImage isMobile={isMobileResolution} />
          {countListings > 0 && !isEmpty(minMaxPercentilPrices) && (
            <VehicleSchema
              listing={listings[0]}
              makeModel={makeModel}
              metaDescription={metaDescription}
              metaName={this.getHeaderContent(seoParams, portal, similarBoatsForOEMs)}
              minMaxPrices={minMaxPercentilPrices}
              totalListings={countListings}
              url={url}
            />
          )}
          {(!isBranded || (isBranded && !isEmpty(partyDetails))) && (
            <Meta
              noindex={meta.getNoIndex(url, isBranded)}
              nofollow={meta.getNoFollow(url, isBranded)}
              canonical={meta.getCanonical(params, isBranded, partyDetails, salesRep)}
              next={meta.getLinkRelNext()}
              prev={meta.getLinkRelPrev()}
              title={metaTitle}
              description={metaDescription}
            />
          )}
          <div className={contentHeadClassName}>
            <BreadCrumb items={breadcrumbs} />
            {this.renderHeader(seoParams, portal, similarBoatsForOEMs, supportsBrandedHeaderV2)}
            {this.state.contactOpen &&
              <ContactForm
                origin={'Rep SRP'}
                open={true}
                itemId={brokerSpotlightId}
                leadType={ContactForm.TYPES.party}
                onClose={this.handleContactButtonClose}
                mode={ContactForm.MODES.modal}
                contactSubmitTrack
              />
            }
            {similarBoatsForOEMs && (
              <AlertMessage
                mainText={t(messages.alertMessage.similarBoatsForOEMs.title)}
                secondaryText={t(messages.alertMessage.similarBoatsForOEMs.description)}
              />
            )}
          </div>

          {
            extendedSeoContent && currentPage === 1 && !this.props.isWorking
            && (isSingleTypeOrClassFilter(activeParams) || isSingleMakeFilter(activeParams)) ?
              <InterestingInformation position="top" isFirstPage={true} content={interestingInformation} /> : null
          }

          <div className={this.props.isWorking ? 'main-content disable-actions' : 'main-content '}>
            {!isMobileResolution &&
              <div id="left-content">
                {isTypeChooserEnabled && mode === MainContent.MODES.results && <TypeChooser url={url} />}
                {toggleProductsFeatureAvailable && <TypeChooserBranded url={url} owner={owner} />}
                <RefineSearchSideBar {...this.props} customUom={customUom}/>

                {isMounted && supportsTridentCalculator &&
                  <>
                    <PaymentCalculator
                      showMonthlyCalculatorOnly
                      tridentTeaserRate={tridentTeaserRate}
                      tridentTermInMonths={240}
                      showPreQualified={false}
                      ctaLink={CTA_APPLY_NOW_CALCULATOR_SRP}
                      useAsCard
                      supportsTridentCalculator={supportsTridentCalculator}
                    />
                  </>
                }

                <div id="right-ad" className="sticky-ads">
                  {<>
                    <AdSlot adParams={newPageAdsConfig} adSlot={'box-1'} />
                    <AdSlot adParams={newPageAdsConfig} adSlot={'box-2'} />
                  </>}
                  {showThirdAdbox && <AdSlot adParams={newPageAdsConfig} adSlot={'box-3'} />}
                </div>
              </div>
            }

            <div id="right-content">
              {isMobileResolution && (
                <MobileRefineSearch {...this.props} customUom={customUom}/>
              )}
              <ResultsHeader {...this.props} isMobile={isMobileResolution} />
              {this.props.sponsored && this.props.sponsored.records ?
                <SponsoredListings
                  sponsoredListings={this.getSponsoredBoats()}
                  isLoading={this.props.isWorking}
                  url={url}
                  tridentTeaserRate={tridentTeaserRate}
                />
                : null
              }
              <div className="search-results">
                <div className="sticky-mobile-add">
                  {isMobileResolution && (<AdSlot adParams={newPageAdsConfig} adSlot={'mobile-leaderboard-custom'} />)}
                </div>
                {this.state.alert && (
                  <AlertMessage
                    mainText={this.state.alert.mainText}
                    secondaryText={this.state.alert.secondaryText}
                  />
                )}
                {listings && (
                  <BoatList
                    adParams={adParams}
                    showAds={true}
                    showPremium={true}
                    listings={listings}
                    similarListings={this.props.similarListings ? this.props.similarListings.records : []}
                    similarListingsType={this.props.similarListings ? this.props.similarListings.type : '' }
                    params={this.props.params}
                    history={this.props.history}
                    url={url}
                    pos={pos}
                    labels={{
                      getTitle: getTitle,
                      getPrice: getPrice,
                      getLocation: getLocation
                    }}
                    intl={this.props.intl}
                    tracking={{ setProductImpression: this.props.setProductImpression, trackingInfo }}
                    cookies={this.props.cookies}
                    customUom={customUom}
                    tridentTeaserRate={tridentTeaserRate}
                    stickyLeaderboard={this.state.stickyLeaderboard}
                    newPageAdsConfig={newPageAdsConfig}
                    isMobileResolution={isMobileResolution}
                  />
                )}

                <ResultsPagination
                  {...this.props}
                  maxPages={maxPages}
                  onClick={
                    () => {
                      scroll.scrollTo('#root');
                    }
                  }
                />
                {isMobileResolution && isMounted && supportsTridentCalculator && (
                  <>
                    <PaymentCalculator
                      initialState="closed"
                      showMonthlyCalculatorOnly
                      tridentTeaserRate={tridentTeaserRate}
                      tridentTermInMonths={240}
                      showPreQualified={false}
                      ctaLink={CTA_APPLY_NOW_CALCULATOR_SRP}
                      supportsTridentCalculator={supportsTridentCalculator}
                      useAsCard
                    />
                  </>
                )}

                {priceDisclaimer ? <p className="price-disclaimer">{priceDisclaimer}</p> : null}

                {isSrpSeoEnabled && currentPage === 1 && !this.props.isWorking ?
                  <SeoContent params={params}/> : null
                }
                {extendedSeoContent && currentPage === 1 && !this.props.isWorking ?
                  <>
                    {
                      (isSingleTypeOrClassFilter(activeParams) || isSingleMakeFilter(activeParams))
                      && <InterestingInformation title={this.getSeoContentH2Text(interestingInformation)} position="bottom" isFirstPage={true} content={interestingInformation} />
                    }
                    {(videos?.length > 0 || relatedBoatArticles?.length > 0) && (
                      <ExpertReviewsTitle
                        searchParams={params}
                        t={t}
                        titleI18n={messages.searchPageSEO.page.expertsReviews.heading}
                        makeModel={makeModel}
                      />
                    )}
                    <YoutubeVideos videos={videos} portal={portal} />
                    <RelatedBoatArticles isFirstPage={true} relatedBoatArticles={relatedBoatArticles} />
                    <WordsmithContent
                      isFirstPage={true}
                      content={wordsmithContent}
                      hasMoreThanOneListing={listings?.length > 1}
                    />
                  </>
                  : null
                }
                {currentPage === 1 && !isEmpty(this.props.OEMDetails) ?
                  <OEMDetails
                    details={this.props.OEMDetails}
                    params={params}
                    facets={facets}
                    isLoading={this.props.isWorking}/> : null}
                {this.props.content === null && !this.props.similarBoatsForOEMs ?
                  <DynamicContent
                    params={params}
                    facets={facets}
                    count={this.props.search.count}
                    customUom={customUom}
                    seoMakeInfo={seoMakeInfo}
                  /> : null}
                {!this.props.similarBoatsForOEMs ?
                  <RelatedContent seoMake={seoMakeInfo?.seoMakeName} params={params} facets={facets} /> : null}

              </div>
            </div>
          </div>
        </AdProvider>
      </>
    );
  }
}

MainContent.MODES = {
  results: 'results',
  branded: 'branded'
};

MainContent.defaultProps = {
  mode: MainContent.MODES.results,
  partyDetails: {},
};

MainContent.propTypes = {
  cookies: PropTypes.instanceOf(Cookies),
  history: PropTypes.object,
  isWorking: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  mode: PropTypes.oneOf(Object.keys(MainContent.MODES)),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
  }).isRequired,
  partyDetails: PropTypes.shape({
    bannerPath: PropTypes.string,
    description: PropTypes.string,
    logoPath: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
    normalizedName: PropTypes.string,
    address: PropTypes.object,
    multiLangDescriptions: PropTypes.object,
    hasBothProductsEnginesAndBoats: PropTypes.bool
  }),
  search: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes),
    count: PropTypes.number
  }),
  similarListings: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes),
    count: PropTypes.number,
    type: PropTypes.string,
  }),
  params: PropTypes.object,
  sponsored: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes),
    count: PropTypes.number
  }),
  seoMakeInfo: PropTypes.shape({
    make: PropTypes.string.isRequired,
    seoMakeName: PropTypes.string.isRequired
  }),
  setProductImpression: PropTypes.func.isRequired,
  trackSearchCount: PropTypes.func.isRequired,
  similarBoatsForOEMs: PropTypes.bool,
  OEMDetails: listingPropTypes,
  spotlightDetails: PropTypes.shape({
    party: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      brokerSpotlight: PropTypes.bool,
      details: PropTypes.shape({
        brokerImageUrl: PropTypes.string,
        brokerName: PropTypes.string,
        normalizedBrokerName: PropTypes.string,
        brokerageLogoUrl: PropTypes.string,
        parentName: PropTypes.string,
        brokerSpotlightConnection: PropTypes.bool,
        brokerSpotlightId: PropTypes.string
      })
    })
  }),
  tridentTeaserRate: PropTypes.number,
  isLeadSubmitted: PropTypes.bool,
  ficoScores: PropTypes.array,
  content: PropTypes.object,
  abTestContext: PropTypes.object,
  adsData: PropTypes.object
};

MainContent.contextType = PortalConfigContext;

const mapStateToProps = state => {
  const searchResults = get(state.app, 'data', {});
  return {
    content: get(searchResults, 'interestingInformation', null)
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    setProductImpression,
    trackSearchCount
  }, dispatch)
)(injectIntl(MainContent));
