import { useState } from 'react';
import {countErrNumber} from '../utils/';


export const useNumberRangeErrors = () => {
  const [formState, setFormState] = useState({
    errors: {},
    showErrors: {}
  });

  const onError = (filter, errs) => {
    const prev = formState.errors;

    const newErrors = {
      ...prev,
      [filter]: errs
    };

    formState.errors = newErrors;
  };

  const displayFormErrors = () => {
    setFormState((prev) => ({
      ...prev,
      showErrors: formState.errors
    }));

    return countErrNumber(formState.errors) > 0;
  };

  const clearAllErrors = () => {
    setFormState({
      errors: {},
      showErrors: {}
    });
  };

  const clearErrors = (data) => {
    const {length = {}, year = {}, price = {}} = data;

    const isLengthEmpty = Object.keys(length).length === 0;
    const isYearEmpty = Object.keys(year).length === 0;
    const isPriceEmpty = Object.keys(price).length === 0;

    setFormState((prev) => ({
      ...prev,
      errors: {
        ...prev.errors,
        ...(isLengthEmpty && { length: {}}),
        ...(isYearEmpty && { year: {}}),
        ...(isPriceEmpty && { price: {}})
      },
      showErrors: {
        ...prev.showErrors,
        ...(isLengthEmpty && { length: {}}),
        ...(isYearEmpty && { year: {}}),
        ...(isPriceEmpty && { price: {}})
      }
    }));
  };

  const getErrors = () => {
    return formState.showErrors;
  };


  return {
    onError,
    displayFormErrors,
    clearAllErrors,
    clearErrors,
    getErrors
  };

};
