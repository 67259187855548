import React from 'react';
import PropTypes from 'prop-types';
import { THREE_COL_LISTINGS_CONTAINER, THREE_COL_SPONSORED_BOATS } from '../../../../../utils/domParserHelper';
import { getListingBlockClass } from './threeColumnHelpers';


const ListingsWithSponsored = ({ device, topListingBlock, bottomListingBlock, sponsoredListingBlock, isSponsoredEnabled }) => {
  const firstClassName = getListingBlockClass(device, topListingBlock.length) + ' top-listings-block';
  const secondClassName = getListingBlockClass(device, bottomListingBlock.length) + ' bottom-listings-block';
  return (
    <>
      <div className={firstClassName} data-testid={THREE_COL_LISTINGS_CONTAINER}>
        {topListingBlock}
      </div>
      {isSponsoredEnabled && <div className="container extra-sponsored" data-testid={THREE_COL_SPONSORED_BOATS}>
        {sponsoredListingBlock}
      </div>}
      <div className={secondClassName} data-testid={THREE_COL_LISTINGS_CONTAINER}>
        {bottomListingBlock}
      </div>
    </>
  );
};

ListingsWithSponsored.propTypes = {
  device: PropTypes.string,
  formatMessage: PropTypes.func,
  topListingBlock: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  bottomListingBlock: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  sponsoredListingBlock: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isSponsoredEnabled: PropTypes.bool,
};

export default ListingsWithSponsored;
