import React, { useState, useEffect, useContext, useMemo, useCallback, memo } from 'react';
import { useIntl } from 'react-intl';
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';

import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import SearchAlertsContainer from '../SearchAlerts/components/Boats';
import MainContent from '../../MainContent';
import PillBox from '../../../../components/PillBox';
import {
  FilterMake,
  FilterYear,
  FilterYearForm,
  FilterClass,
  FilterModels,
  FilterLength,
  FilterLengthForm,
  FilterLocation,
  FilterFuelType,
  FilterForSaleBy,
  FilterHullMaterial,
  FilterEngineDetails,
  FilterPriceForm,
} from '../Filter';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleHeader
} from '../../../../components/Collapsible';
import {
  getPortalDefaultCountry,
  portalHasCountryFilter,
  isFilterEnabledForPortal
} from '../../../../utils/locationHelper';
import {
  getRegions,
  getSubdivisions,
  getCities
} from '../../../../utils/multiFacetHelper';
import {
  setGenericEvent,
  setSearchTracking
} from '../../../../store/actions/dataLayer';
import {
  getDefaultParams,
  getActiveParams,
} from '../../../../utils/urlHelpers/boats';
import { PortalConfigContext } from '../../../../config/portal';
import { getCurrentLocale } from '../../../../utils/language';

import { scrollToTop } from '../../../../utils/scrollTo';
import { getRadiuses } from '../../utils/radiusSearch';

import './styles.css';
import {yieldToMain} from '../../../../utils';
import { getMessages } from '../../../../tppServices/translations/messages';
import { useTPPServices } from '../../../../tppServices/tppDIHooks';
import isEqual from 'lodash/isEqual';

/* Loadable Facets */
const FilterCondition = loadable(() => import('../Filter/FilterCondition'));
const FilterPrice = loadable(() => import('../Filter/FilterPrice'));

const shouldCollapsibleBeOpen = (facetsConfig, facetName) => {
  const facetConfig = facetsConfig.filter(
    (facet) => facet.name === facetName
  )[0];
  return get(facetConfig, 'initialState.open', false);
};

const RefineSearch = (props) => {
  const dispatch = useDispatch();
  const context = useContext(PortalConfigContext);
  const [state, setState] = useState({
    initialState: props.initialState || 'open',
    data: getDefaultParams(get(props, 'match.params', {})),
    wasRegionChangedByUser: false
  });
  const { boatsConstants } = useTPPServices();
  const { formatMessage: t } = useIntl();
  const messages = getMessages();

  useEffect(() => {
    setCurrentState();
  }, [props?.match?.url]);

  const setCurrentState = () => {
    const params = getCurrentState();
    setState({
      data: params
    });
  };

  const getCurrentState = () => {
    const theDefault = getDefaultParams(get(props, 'match.params', {}));
    return theDefault;
  };

  const getLocationFilterConfig = (facetConfig, tracking) => {
    const locationFilterConfig = {};

    locationFilterConfig.shouldCollapsibleBeOpen = {
      radius: shouldCollapsibleBeOpen(facetConfig, 'radius')
        ? 'open'
        : 'closed',
      country: shouldCollapsibleBeOpen(facetConfig, 'country')
        ? 'open'
        : 'closed',
      city: shouldCollapsibleBeOpen(facetConfig, 'city') ? 'open' : 'closed',
      region: shouldCollapsibleBeOpen(facetConfig, 'region')
        ? 'open'
        : 'closed',
      subdivision: shouldCollapsibleBeOpen(facetConfig, 'subdivision')
        ? 'open'
        : 'closed'
    };
    locationFilterConfig.tracking = tracking;

    return locationFilterConfig;
  };

  const getLocationFilterData = (filtersData, facets) => {
    const radiuses = getRadiuses();
    const selectedCountry = portalHasCountryFilter(context)
      ? filtersData.country
      : getPortalDefaultCountry();
    const selectedRadius = radiuses.find((r) => r.value === filtersData.radius);
    const locationFilterData = {
      radiusesData: {
        radiuses: radiuses,
        selected: selectedRadius
      },
      countriesData: {
        countries: get(facets, 'country', []),
        selected: selectedCountry
      },
      citiesData: {
        cities: getCities(facets, selectedCountry),
        selected: filtersData.city
      },
      regionsData: {
        regions: sortBy(getRegions(facets, selectedCountry), 'value'),
        selected: filtersData.region
      },
      subdivisionsData: {
        subdivisions: getSubdivisions(facets, selectedCountry),
        selected: filtersData.subdivision
      }
    };

    return locationFilterData;
  };

  const updateStateOnChange = useCallback(async(type, value) => {
    await yieldToMain();
    let wasRegionChangedByUser = state.wasRegionChangedByUser;
    let location = {};

    if (type === 'region') {
      location.subdivision = '';
      wasRegionChangedByUser = value !== 'all';
      location.city = [];
    }

    if (type === 'subdivision') {
      location.city = [];
    }

    if (type === 'country') {
      location.radius = '0';
      location.region = '';
      location.subdivision = '';
      location.city = [];
    }

    if (type === 'radius') {
      location.country = '';
      location.region = '';
      location.subdivision = '';
      location.city = [];
    }

    const newState = {
      data: {
        ...state.data,
        [type]: value,
        ...location
      },
      wasRegionChangedByUser
    };
    setState(newState);
    return newState;
  }, [state.wasRegionChangedByUser, state.data]);

  const onChangedFilters = useCallback(async (
    type, value
  ) => {
    const newState = await updateStateOnChange(type, value);
    props.onDataChange(newState.data);
  }, [updateStateOnChange, props.onDataChange]);

  const debounceOnDataChange = useCallback(debounce((data) => {
    props.onDataChange(data);
  }), [props.onDataChange]);

  const handleDataChange = useCallback(async (type, value) => {
    const newState = await updateStateOnChange(type, value);
    debounceOnDataChange(newState.data);
  }, [updateStateOnChange, debounceOnDataChange]);

  const handleDataClear = useCallback(async () => {
      await yieldToMain();
      dispatch(setGenericEvent('site search', 'guided search', 'clear filters'));
      const newState = {
        data: {
          ...getDefaultParams({}),
          owner: undefined,
          sort: undefined
        }
      };
      setState(newState);

      props.onDataChange(newState.data);
      props.onClearErrors && props.onClearErrors();
      scrollToTop();
  }, [props.onDataChange]);

  const facetAddedTracker = (facet) => {
    dispatch(setSearchTracking(facet, 'single', 'guided search'));
  };

  const facetRemovedTracker = (facet) => {
    dispatch(setGenericEvent('site search', 'guided search', facet));
  };

    const params = getCurrentState();
    const currentParams = getActiveParams(params);

    const tracking = useMemo(() => ({
      facetAdded: facetAddedTracker,
      facetRemoved: facetRemovedTracker
    }), []);

    const {
      mobileParams,
      customUom
    } = props;
    const { data } = state;
    const {
      length,
      year,
      price,
      fuelType,
      hullMaterial,
      multiFacetedBoatTypeClass,
      makeModel,
    } = data;
    const facets = get(props, 'facets', []);
    const seoMakeInfo = get(props, 'seoMakeInfo');
    const makes = get(facets, 'make', []);
    const models = get(facets, 'makeModel', []);
    const classFacets = get(facets, 'class', []);
    const fuelTypeFacets = get(facets, 'fuelType', []);
    const hullMaterialFacets = get(facets, 'hullMaterial', []);
    const facetConfig = context.pages.searchResults.facets;
    const isBranded = props.mode === MainContent.MODES.branded;
    const currentLanguage = getCurrentLocale();
    const searchAlerts =
      props.position === 'desktop' &&
      get(context.supports, 'searchAlerts') &&
      !get(
        context.languages[currentLanguage],
        'disableSearchAlerts',
        false
      );
    const radiusSearch = get(context.supports, 'radiusSearch', {
      enabled: false,
      brandingV2: false
    });

    return (
      <>
        {searchAlerts && (
          <SearchAlertsContainer
            match={props.match}
            eventCategory="above search filter"
            active={currentParams}
            makeModel={models}
            seoMakeInfo={seoMakeInfo}
          />
        )}
        <PillBox
          customUom={customUom}
          active={mobileParams || currentParams}
          params={getDefaultParams({})}
          handleDataChange={ handleDataChange }
          onClear={ handleDataClear }
          makeModel={models}
          seoMakeInfo={seoMakeInfo}
          searchType={ boatsConstants.SEARCH_TYPES.boats }
        />
        <FilterLocation
          position={props.position}
          params={params}
          tracking={tracking}
          radiusSearch={radiusSearch}
          filtersConfig={getLocationFilterConfig(facetConfig, tracking)}
          filtersData={getLocationFilterData(data, facets)}
          handleDataChange={ handleDataChange }
        />

        { isFilterEnabledForPortal('condition', context) && (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'condition')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.condition)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterCondition
                position={props.position}
                condition={data.condition}
                handleDataChange={ handleDataChange }
                tracking={tracking}
                id={`filter-condition-${props.position}`}
                params={params}
              />
            </Collapsible>
          </CollapsibleContent>
        ) }

        { isFilterEnabledForPortal('length', context) && (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'length') ? 'open' : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.length)}
            </CollapsibleHeader>
            <Collapsible>
              { props.position === 'desktop' ? (
                <FilterLengthForm
                  customUom={customUom}
                  cookies={props.cookies}
                  min={length.min}
                  max={length.max}
                  handleDataChange={ onChangedFilters }
                />
              ) : (
                <FilterLength
                  customUom={customUom}
                  cookies={ props.cookies }
                  min={length.min}
                  max={length.max}
                  isThreeColumnLayout={ props.isThreeColumnLayout }
                  id="filter-length"
                  handleDataChange={ onChangedFilters }
                  position={ props.position }
                />
              ) }
            </Collapsible>
          </CollapsibleContent>
        )}

        <CollapsibleContent initialState="open">
          <CollapsibleHeader priority={0}>
            {t(messages.price)}
          </CollapsibleHeader>
          <Collapsible extraClass="collapsible-price">
            { props.position === 'desktop' ? (
              <FilterPriceForm
                min={price.min}
                max={price.max}
                handleDataChange={ onChangedFilters }
                tracking={tracking}
                userCurrency={ props.userCurrency }
              />
            ) : (
              <FilterPrice
                min={price.min}
                max={price.max}
                handleDataChange={ onChangedFilters }
                tracking={tracking}
                isThreeColumnLayout={ props.isThreeColumnLayout }
                userCurrency={ props.userCurrency }
                position={ props.position }
              />
            )}
          </Collapsible>
        </CollapsibleContent>

        { props.position === 'desktop' ? (
          <CollapsibleContent initialState="open">
            <CollapsibleHeader priority={0}>{ t(messages.year) }</CollapsibleHeader>
            <Collapsible>
              <FilterYearForm
                min={ year.min }
                max={ year.max }
                handleDataChange={ onChangedFilters }
              />
            </Collapsible>
          </CollapsibleContent>
        ) : (
          <CollapsibleContent initialState="open">
            <CollapsibleHeader priority={0}>{t(messages.year)}</CollapsibleHeader>
            <Collapsible>
              <FilterYear
                min={year.min}
                max={year.max}
                handleDataChange={ onChangedFilters }
                isThreeColumnLayout={ props.isThreeColumnLayout }
                position={ props.position }
              />
            </Collapsible>
          </CollapsibleContent>
        ) }

        <CollapsibleContent initialState="closed">
          <CollapsibleHeader priority={0}>{t(messages.type)}</CollapsibleHeader>
          <Collapsible>
            <FilterClass
              facets={classFacets}
              handleDataChange={ handleDataChange }
              multiFacetedBoatTypeClass={multiFacetedBoatTypeClass}
              params={params}
              position={ props.position }
              tracking={tracking}
            />
          </Collapsible>
        </CollapsibleContent>

        <CollapsibleContent initialState="closed">
          <CollapsibleHeader priority={0}>{t(messages.make)}</CollapsibleHeader>
          <Collapsible>
            <FilterMake
              makes={makes}
              makeModels={makeModel}
              position={ props.position }
              loading={includes( props.componentWorking, 'models')}
              handleDataChange={ handleDataChange }
              params={params}
              tracking={tracking}
            />
          </Collapsible>
        </CollapsibleContent>

        <FilterModels
          makes={makes}
          makeModels={makeModel}
          models={models}
          position={ props.position }
          loading={includes(props.componentWorking, 'models')}
          handleDataChange={ handleDataChange }
          params={params}
          tracking={tracking}
        />

        <FilterEngineDetails
          position={ props.position }
          data={data}
          facets={facets}
          params={params}
          handleDataChange={ handleDataChange }
          tracking={tracking}
        />

        { isFilterEnabledForPortal('fuelType', context) && (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'fuelType')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.fuelType)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterFuelType
                handleDataChange={ handleDataChange }
                fuelTypes={fuelTypeFacets}
                params={params}
                selectedFuelTypes={fuelType}
                tracking={tracking}
                position={ props.position }
              />
            </Collapsible>
          </CollapsibleContent>
        )}

        { isFilterEnabledForPortal('hullMaterial', context) && (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'hullMaterial')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.hullMaterial)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterHullMaterial
                handleDataChange={ handleDataChange }
                hullTypes={hullMaterialFacets}
                params={params}
                selectedHullTypes={hullMaterial}
                position={ props.position }
              />
            </Collapsible>
          </CollapsibleContent>
        )}

        { isFilterEnabledForPortal('forSale', context) && !isBranded && (
          <CollapsibleContent
            initialState={
              shouldCollapsibleBeOpen(facetConfig, 'forSale')
                ? 'open'
                : 'closed'
            }
          >
            <CollapsibleHeader priority={0}>
              {t(messages.forSale)}
            </CollapsibleHeader>
            <Collapsible>
              <FilterForSaleBy
                position={ props.position }
                selectedForSale={data.forSale}
                handleDataChange={ handleDataChange }
                params={params}
                tracking={tracking}
              />
            </Collapsible>
          </CollapsibleContent>
        ) }
      </>
    );
};

RefineSearch.propTypes = {
  match: PropTypes.object,
  componentWorking: PropTypes.arrayOf(PropTypes.string),
  facets: PropTypes.shape({
    class: PropTypes.arrayOf(
      PropTypes.shape({
        /** Class slug */
        value: PropTypes.string,
        count: PropTypes.number,
        /** Formatted class name */
        name: PropTypes.string,
        /** Formatted class type name */
        heading: PropTypes.string
      })
    ),
    country: PropTypes.arrayOf(
      PropTypes.shape({
        /** Country initials */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ),
    fuelType: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
        /** Fuel type slug */
        urlId: PropTypes.string,
        /** Formatted fuel type name */
        name: PropTypes.string,
        heading: PropTypes.string
      })
    ),
    hullMaterial: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        count: PropTypes.number,
        /** Hull material slug */
        urlId: PropTypes.string,
        /** Formatted hull material name */
        name: PropTypes.string,
        heading: PropTypes.string
      })
    ),
    make: PropTypes.arrayOf(
      PropTypes.shape({
        /** Formatted make name */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ),
    makeModel: PropTypes.arrayOf(
      PropTypes.shape({
        /** Formatted make name */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        /** Models list for the make */
        model: PropTypes.arrayOf(
          PropTypes.shape({
            /** Model formatted name */
            value: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired
          })
        )
      })
    ),
    subdivision: PropTypes.arrayOf(
      PropTypes.shape({
        /** State initials */
        value: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    )
  }),
  initialState: PropTypes.string,
  mode: PropTypes.any,
  onDataChange: PropTypes.func.isRequired,
  onClearErrors: PropTypes.func,
  position: PropTypes.string,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  mobileParams: PropTypes.object,
  customUom: PropTypes.shape({
    length: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    weight: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    speed: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    capacity: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    distance: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    }),
    radius: PropTypes.shape({
      name: PropTypes.string,
      abbr: PropTypes.string.isRequired,
      symbol: PropTypes.string
    })
  }),
  isThreeColumnLayout: PropTypes.bool,
  userCurrency: PropTypes.string,
};

export default memo(RefineSearch, isEqual);


