import { useState, useCallback, useContext } from 'react';
import { MobileFilterContext } from '../../../../../../context/MobileFilterContext';


export const useValidateNumberRange = (filterName, validate) => {
  const [errors, setErrors] = useState({});
  const mobileFilterContext = useContext(MobileFilterContext);
  const { onError, allErrors } = mobileFilterContext || {};

  const getInvalidFieldsAndMessages = () => {
    const errorMessages = [];
    const invalidFields = [];

    for (const [filed, message] of Object.entries(allErrors?.[filterName] || {})) {
      invalidFields.push(filed);
      errorMessages.push(message);
    }

    return { invalidFields, errorMessages };
  };

  const validateNumberRange = useCallback((data) => {
    const errors = validate(data);
    setErrors(errors);
    onError && onError(filterName, errors);
    return errors;
  }, [validate]);


  const { errorMessages, invalidFields } = getInvalidFieldsAndMessages();

  return {
    validateNumberRange,
    errors,
    errorMessages,
    invalidFields,
  };
};
