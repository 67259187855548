export const getMultiLangDescriptionsKey = (locale) => {
  switch (locale) {
    case 'us':
    case 'uk':
    case 'au':
    case 'gb':
    case 'en':
      return 'englishDescription';
    case 'dk':
    case 'da':
      return 'danishDescription';
    case 'de':
      return 'germanDescription';
    case 'es':
      return 'spanishDescription';
    case 'it':
      return 'italianDescription';
    case 'nl':
      return 'dutchDescription';
    case 'nb':
    case 'no':
      return 'norwegianDescription';
    case 'ru':
      return 'russianDescription';
    case 'fi':
      return 'finnishDescription';
    case 'sv':
    case 'se':
      return 'swedishDescription';
    default:
      return 'description';
  }
};

export const getPartyDescription = (multiLangDescriptions = {}, description = '', locale) => {
  const descriptionKey = getMultiLangDescriptionsKey(locale);
  return multiLangDescriptions[descriptionKey] || description;
};
